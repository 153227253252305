@import "../settings/colors.scss";

.creditBlock{
  background: $gray;
  width: 90%;
  padding: 30px;
  border-radius: 10px;
  display: inline-block;
  margin: 10px auto 25px auto;
}

.inline{
  display: inline-block;
}

.inlineRight{
  float: right;

  @extend .inline;
}

.progressBarBlock{
  margin-top: 20px;
}

.progressBar{
  background: $purple-color;
}

.alignRight{
  text-align: right;
}

.status{
  border-radius: 10px;
  text-align: center;
  padding: 3px 10px;
  font-weight: bold;
}

.openStatus{
  background: $new-green-color;

  @extend .status;
}

.closedStatus{
  background: $light-red-color;

  @extend .status;
}

.creditTitle{
  margin-top: 50px;
}

.fullWidth{
  width: 100%;
}

.payInput{
  width: 100%;
  border-radius: 10px;
  border: 2px solid $purple-color;
  padding: 5px 10px;
}

.insideInput{
  background: $gray;
}

.payButton{
  width: 150px;
  border-radius: 10px;
  background: $purple-color;
  padding: 5px 15px;
}