@import "../settings/colors.scss";

.layout{
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $background;
  padding: 20px 40px 20px 40px;
  border-radius: 20px;
  width: 600px;
  border: 2px solid $purple-color;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 30px;
  max-height: 700px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: $zero-opacity;
  }

  &::-webkit-scrollbar-thumb {
    background: $zero-opacity;
    border-radius: 10px;
    height: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $zero-opacity;
  }
}

.layout p{
  color: $text-color;
}

.blueText{
  color: $blue-color;
}

.whiteText{
  color: $white-color
}

.redText{
  color: $red_color;
}

.lightRedText{
  color: $light-red-color;
}

.cyanText{
  color: $cyan-color;
}

.purpleText{
  color: $purple-color;
}

.orangeText{
  color: $orange-color;
}

.greenText{
  color: $light-green-color;
}

.submit_button{
  border: 0;
  border-radius: 10px;
  background: linear-gradient(342deg, rgba(111,43,255,1) 36%, rgba(50,43,255,1) 78%, rgba(43,103,255,1) 100%);
  color: $text-color;
  padding: 5px 45px;
  font-size: 24px;
  margin: 50px auto 10px auto;
  display: block;
  font-family: "bold", sans-serif;
}

@media screen and (max-width: 650px){
  .layout{
    width: 90%;
  }
}