@import "../settings/colors.scss";

.header{
  padding: 10px 20px;
  //padding: 30px 60px;
}

.logo img, .user img{
  display: inline-block;
  width: 30px;
}

.userAvatar{
  border-radius: 10px;
}

.logo h5, .user h5{
  display: inline-block;
  color: $text-color;
  margin: 0 10px;
  vertical-align: -2px;
}

.user{
  float: right;
}

.loginButton{
  background: $purple-color;
  border: none;
  color: $text-color;
  padding: 7px 25px;
  border-radius: 10px;
  font-size: 18px;
  float: right;
}

@media screen and (max-width: 800px){
  .header{
    padding: 30px 20px;
  }
}

@media screen and (max-width: 700px){
  .user h5{
    font-size: 14px;
  }
}

@media screen and (max-width: 500px){
  .user h5{
    display: none;
  }
}