@import "../settings/colors.scss";

body{
  background: #0d0d11;
  padding: 30px 50px;
}

.header img{
  display: inline-block;
  width: 30px;
}

.header h5{
  display: inline-block;
  color: white;
  margin-left: 10px;
  vertical-align: -2px;
}

h1{
  text-align: center;
}

.divider{
  color: $purple-color;
  margin: -1px auto 60px auto;
  width: 400px;
}

.credit_layout{
  margin-top: 80px;
}

h4{
  text-align: center;
  margin: 50px 0 30px 0;
}

input{
  display: block;
  margin: -10px auto 0 auto;
}

.textInput{
  background: none;
  color: $text-color;
  padding: 3px 10px;
  border: 2px solid $purple-color;
  border-radius: 10px;
  width: 250px;
  transition: 0.3s;

  &:focus{
    outline: none;
  }
}

.rangeInput{
  display: block;
  margin: -10px auto 15px auto;
  -webkit-appearance: none;
  width: 250px;
  height: 15px;
  border-radius: 5px;
  background: $purple-color;
  outline: none;
  opacity: 0.7;
  transition: 0.3s;
}

.rangeInput[show="false"], .rangeDescription[show="false"], .floatingUpToDate[show="false"]{
  display: none;
}

.rangeDescription{
  color: $purple-color;
  font-size: 20px;
  text-align: center;
  margin-top: 15px;
}

.playTime_button{
  display: block;
  margin: 0 auto 4px auto;
  transition: 0.3s;
  background: none;
  border: 2px solid $purple-color;
  border-radius: 15px;
  padding: 5px 30px;
  color: $text-color;
  text-align: center;
  &:hover{
    transition: 0.3s;
    background: $purple-color;
    -webkit-box-shadow: 0px 0px 15px 4px rgba(101,100,219,1);
    -moz-box-shadow: 0px 0px 15px 4px rgba(101,100,219,1);
    box-shadow: 0px 0px 15px 4px rgba(101,100,219,1);
  }
}

.playTime_button[status="active"]{
  background: $purple-color;
  transition: 0.3s;
}

h6{
  font-size: 30px;
  text-align: center;
  transition: 0.5s;
}

h6[color="dark_red"]{
  color: $darken-red-color;
}

h6[color="red"]{
  color: $red-color;
}

h6[color="light_red"]{
  color: $light-red-color;
}

h6[color="orange"]{
  color: $orange-color;
}

h6[color="yellow"]{
  color: $yellow-color;
}

h6[color="light_yellow"]{
  color: $light_yellow-color;
}

h6[color="green"]{
  color: $green-color;
}

h6[color="light_green"]{
  color: $light-green-color;
}

.submit_button{
  border: 0;
  border-radius: 10px;
  background: linear-gradient(342deg, rgba(111,43,255,1) 36%, rgba(50,43,255,1) 78%, rgba(43,103,255,1) 100%);
  color: $text-color;
  padding: 5px 45px;
  font-size: 24px;
  margin: 50px auto 40px auto;
  display: block;
  font-family: "bold", sans-serif;
}

.blurBackground{
  z-index: 900;
  margin-top: -100px;
  margin-left: -100px;
  position: fixed;
  width: 200vw;
  height: 200vh;
  background: rgba(0,0,0, 0.8);
  opacity: 0.9;
}

.alert_window[status="active"]{
  display: block;
}

.alert_window[status="inactive"]{
  display: none;
}

.textInput[valid="false"]{
  border: 2px solid $red-color;
}

.playTime_button[valid="false"]{
  border: 2px solid $red-color;

  &:hover{
    transition: 0.3s;
    background: $red-color;
    -webkit-box-shadow: 0px 0px 15px 4px rgb(219, 100, 100);
    -moz-box-shadow: 0px 0px 15px 4px rgb(219, 100, 100);
    box-shadow: 0px 0px 15px 4px rgb(219, 100, 100);
  }
}

.rangeInput[valid="false"]{
  background: $red-color;
}

.rangeDescription[valid="false"]{
  color: $red-color;
}

.creditTypes{
  margin: 70px 0 -50px 0;
}

.selectContent{
  text-align: center;
  width: 90%;
  display: block;
  margin: 0 auto 50px auto;
  padding-bottom: 5px;
}

.selectContent[status="active"]{
  border-bottom: 2px solid $purple-color;
}

.floatingUpToDate{
  text-align: center;
  color: $purple-color;
  margin-top: -20px;
}

.infoButton{
  width: 12px;
  position: relative;
  bottom: 7px;
}

.infoText{
  background: #14141a;
  text-align: center;
  padding: 10px 20px;
  width: 400px;
  font-size: 11px;
  opacity: 0.6;
  border-radius: 15px;
  position: absolute;
  top: 535px;
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 800px){
  .infoText{
    top: 565px;
  }
}

@media screen and (max-width: 500px){
  .divider{
    width: 90%;
  }
}