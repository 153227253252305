$background: #0d0d11;
$gray: #262626;
$text-color: #f5f5f5;
$white-color: #ffffff;
$purple-color: #6564DB;
$blue-color: #5392ff;
$cyan-color: #69d2c7;
$darken-red-color: #bb3a3a;
$red-color: #db6464;
$light-red-color: #ff4545;
$orange-color: #ffbe59;
$yellow-color: #FAFF00;
$light-yellow-color: #FFFF74;
$green-color: #4cff47;
$light-green-color: #6fff6b;
$new-green-color: #59d259;
$zero-opacity: rgba(0, 0, 0, 0);